import { useEffect, useState } from 'react';
import { Container, Typography, Skeleton, Grid, Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import Page from '../components/Page';
import ProductList from '../components/ProductList';
import { useAccount } from '../hooks/useAccount';
import ContainerMessage from '../components/ContainerMessage';
import HelperTooltip from '../components/HelperTooltip';

const LIST_PRODUCTS = gql`
  query listProducts($domain: ID!, $limit: Int!, $nextToken: String) {
    listProducts(domain: $domain, limit: $limit, nextToken: $nextToken) {
      items {
        productId
        title
        imgLink
        price
        link
      }
      nextToken
    }
  }
`;

const GET_PRODUCTS_STATS = gql`
  query getProductsStats($eshopId: ID!) {
    getEshop(id: $eshopId) {
      feedProductCount
      lastFeedUpdateAt
    }
  }
`;

const limit = 12;

export default function EcommerceShop() {
  const { t } = useTranslation();
  const {
    eshop: { domain, id }
  } = useAccount();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { data, loading, error, fetchMore, refetch } = useQuery(LIST_PRODUCTS, {
    variables: {
      domain,
      limit,
      nextToken: null
    }
  });
  const { data: statsData, loading: statsLoading } = useQuery(GET_PRODUCTS_STATS, {
    variables: {
      eshopId: id
    }
  });

  useEffect(() => {
    refetch({
      domain,
      limit
    });
  }, [domain, refetch]);

  const loadMore = async () => {
    setFetchMoreLoading(true);
    await fetchMore({
      variables: {
        limit,
        nextToken
      }
    });
    setFetchMoreLoading(false);
  };

  if (error) return <h1>ERROR LOADING DATA</h1>;
  const products =
    data?.listProducts?.items?.map(({ productId, title, price, imgLink, link }) => ({
      id: productId,
      title,
      cover: imgLink,
      price,
      link
    })) || [];
  const nextToken = data?.listProducts?.nextToken;
  return (
    <Page title={t('pages.products')}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 5
          }}
        >
          <Typography variant="h4">
            {t('general.products')}
            <Box
              component="span"
              sx={{
                marginLeft: 1
              }}
            >
              {statsData?.getEshop?.feedProductCount > 0 &&
                `(${statsData?.getEshop?.feedProductCount})`}
            </Box>
          </Typography>
          <Typography variant="caption">
            {statsLoading ? (
              <Skeleton width={100} />
            ) : (
              statsData?.getEshop?.lastFeedUpdateAt &&
              `${t('products.last_update_at', {
                val: new Date(statsData?.getEshop?.lastFeedUpdateAt)
              })}`
            )}
            <HelperTooltip title={t('products.last_update_tooltip')} />
          </Typography>
        </Box>
        {loading && (
          <Grid container spacing={3}>
            {[...Array(limit)].map((_, i) => (
              <Grid key={i} item xs={12} sm={6} md={3}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        )}
        {products.length === 0 ? (
          <ContainerMessage
            title={t('products.no_products_title')}
            subtitle={t('products.no_products_subtitle')}
          />
        ) : (
          <>
            <ProductList products={products} />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ my: 2 }}
              onClick={loadMore}
              disabled={!nextToken}
              loading={fetchMoreLoading || loading}
            >
              {t('general.show_more')}
            </LoadingButton>
          </>
        )}
      </Container>
    </Page>
  );
}
